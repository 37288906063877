import React, { useState, useContext, useEffect, useCallback } from 'react';
import * as S from './ToggleContainer.styles';
import FunnelContext from '../../../context/FunnelContext';
import useLocalStorage from '../../../hooks/useLocalStorage';

const ToggleContainerSingle = props => {
  const {
    builderBlock,
    children,
    bgColor,
    activeColor,
    hoverColor,
    extraObjects,
    showTickbox,
    tickboxColor,
    tickboxRadius,
    currentTubIndex,
    currentCategory,
    setAnswer,
    useCheckmark = true
  } = props;

  const [active, setActive] = useState(false);
  const [currentTubIndex2, setCurrentTubIndex2] = useLocalStorage(
    'currentTubIndex',
    null
  );
  const [currentCategory2, setCurrentCategory2] = useLocalStorage(
    'currentCategory',
    null
  );

  const {
    extraObjects: extraData,
    setExtraObjects,
    currentTubIndex: currentTubIndexContext,
    setCurrentTubIndex,
    currentCategory: currentCategoryContext,
    setCurrentCategory,
    answers,
    setAnswers
  } = useContext(FunnelContext);

  const [extraObjects2, setExtraObjects2] = useLocalStorage('extraObjects', {});
  const [answersLS, setAnswersLS] = useLocalStorage('answers', {});

  const noChildren = () => {
    return <S.NoChildren>insert components here</S.NoChildren>;
  };

  const extraObjectValue = (key, value, type, extra) => {
    let newVal;
    switch (type) {
      case 'list':
        const existing = extra[key] || [];
        newVal = [...new Set([...existing, value])];
        break;
      case 'boolean':
        newVal = value ? true : false;
        break;
      default:
      case 'string':
        newVal = value;
        break;
    }
    return { [key]: newVal };
  };

  const initiateData = useCallback(
    isActive => {
      const newExtraData = extraData;
      const newAnswers = answers;
      const newAnswersLS = answersLS;
      if (extraObjects?.length > 0) {
        extraObjects.map(({ key, value, type }) => {
          if (isActive) {
            Object.assign(
              newExtraData,
              extraObjectValue(key, value, type, newExtraData)
            );
            if (setAnswer) {
              Object.assign(
                newAnswers,
                extraObjectValue(key, value, type, newAnswers)
              );
              Object.assign(
                newAnswersLS,
                extraObjectValue(key, value, type, newAnswersLS)
              );
            }
          } else {
            switch (type) {
              case 'list':
                newExtraData[key].splice(newExtraData[key].indexOf(value), 1);
                if (setAnswer) {
                  newAnswers[key].splice(newAnswers[key].indexOf(value), 1);
                  newAnswersLS[key].splice(newAnswersLS[key].indexOf(value), 1);
                }
                break;
              default:
                delete newExtraData[key];
                if (setAnswer) {
                  delete newAnswers[key];
                  delete newAnswersLS[key];
                }
                break;
            }
          }
          return null;
        });
      }

      if (isActive) {
        Object.assign(newExtraData, { [builderBlock.id]: true });
      } else {
        Object.assign(newExtraData, { [builderBlock.id]: false });
      }

      setExtraObjects2(newExtraData);
      setExtraObjects(newExtraData);

      if (setAnswer) {
        setAnswers(newAnswers);
        setAnswersLS(newAnswersLS);
      }
    },
    [
      extraData,
      extraObjects,
      setExtraObjects,
      setExtraObjects2,
      builderBlock,
      answers,
      answersLS,
      setAnswer,
      setAnswers,
      setAnswersLS
    ]
  );

  useEffect(() => {
    const isActive = extraObjects2[builderBlock.id] || false;
    setActive(isActive);
    const newExtraData = Object.assign(extraData, extraObjects2);
    setExtraObjects(newExtraData);
    setExtraObjects2(newExtraData);
    if (isActive) {
      initiateData(isActive);
    }
    if (currentTubIndex2) {
      setCurrentTubIndex(currentTubIndex2);
    }
    if (currentCategory2) {
      setCurrentCategory(currentCategory2);
    }
  }, [
    extraData,
    extraObjects2,
    setActive,
    builderBlock,
    setExtraObjects,
    setExtraObjects2,
    initiateData,
    currentTubIndex2,
    currentCategory2,
    setCurrentTubIndex,
    setCurrentCategory
  ]);

  const handleClick = () => {
    const isActive = !active;
    setActive(isActive);
    initiateData(isActive);

    setCurrentTubIndex(currentTubIndex || currentTubIndexContext);
    setCurrentCategory(currentCategory || currentCategoryContext);
    setCurrentTubIndex2(currentTubIndex || currentTubIndexContext);
    setCurrentCategory2(currentCategory || currentCategoryContext);
  };

  const Container = () => {
    return (
      <S.Container
        onClick={handleClick}
        active={active}
        bgColor={bgColor}
        hoverColor={hoverColor}
        activeColor={activeColor}
      >
        {showTickbox && (
          <S.Dot
            active={active}
            tickboxRadius={tickboxRadius}
            tickboxColor={tickboxColor}
            useCheckmark={useCheckmark}
          />
        )}
        <div>{children || noChildren()}</div>
      </S.Container>
    );
  };

  return <Container />;
};

export default ToggleContainerSingle;
